import { library } from "@fortawesome/fontawesome-svg-core";
import { faUser } from "@fortawesome/free-solid-svg-icons";
import React from "react";
import styled from "@emotion/styled";
// import myImage from "../img/myImage.png";
import falkenberg from "../img/falkenberg.jpeg";

library.add(faUser);

const Grid = styled.div`
  grid-area: kicksve;
  display: grid;
  align-items: center;
  justify-items: center;
  background-color: white;
  grid-template-areas:
    "pic"
    "text";

  @media (min-width: 1200px) {
    display: grid;
    align-items: left;
    justify-items: left;
    grid-auto-columns: 1fr;
    grid-template-areas: "text pic";
  }
`;

const Text = styled.div`
  grid-area: text;
  padding-bottom: 80px;
  padding-left: 20px;
  padding-right: 20px;
  max-width: 1200px;

  @media (min-width: 768px) {
    padding-left: 7%;
    padding-right: 7%;
  }
`;

const PictureContainer = styled.div`
  grid-area: pic;
  width: 100%;
  object-fit: cover;
  height: 100%;
`;

const Picture = styled.img`
  grid-area: pic;
  width: 100%;
  object-fit: cover;
  height: 100%;
`;

const Wrapper = styled.div`
  padding-bottom: 50px;
  background-color: #2f5597;
`;

const Title = styled.div`
  font-size: 25px;
  font-weight: 700;
  padding-top: 54px;
  line-height: 1em;
  color: white;
  padding-left: 20px;
  padding-bottom: 20px;
  text-align: center;
  background-color: #2f5597;
`;

const SideTitle = styled.div`
  font-size: 45px;
  font-weight: 700;
  padding-top: 54px;
  line-height: 1em;
  color: black;
  padding-left: 20px;
  padding-bottom: 20px;
  text-align: center;
  background-color: white;
`;

const FullWidthText = styled.div`
  font-weight: 400;
  font-size: 20px;
  color: white;
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 20px;
  line-height: 28px;
  width: 100%;
`;

const SingleTextLeft = styled.div`
  max-width: 600px;
  padding-bottom: 50px;
  float: left;
`;

const IntroText = styled.p`
  text-align: center;
  font-style: bold;
`;

const Toolbox = () => {
  return (
    <section id="tool-box">
      <Grid>
        <PictureContainer>
          <Picture src={falkenberg} alt="logo" />
        </PictureContainer>

        <Text>
          <SideTitle>Verktygslåda</SideTitle>
          <IntroText>
            <p>Min resa till kompetens och erfarenheter är lång.</p>
            <p>
              En resa som under åren präglats av stor passion och engagemang för
              att tillsammans skapa magi, kundnytta, relevanta erbjudanden och
              hållbar ekonomisk tillväxt.
            </p>
            <p>
              En resa som gett mig ett stort intresse och affärsmässigt
              helhetsperspektiv på hur VI tillsammans skapar innovation och
              lyfter på olika stenar för nya möjligheter.{" "}
            </p>
            <p>
              En resa som utvecklats till KicSve AB, en specialiserad generalist
              inom detaljhandeln med en bred verktygslåda.
            </p>
          </IntroText>

          <ul style={{ marginTop: 20 }}>
            <li>Strategi & Mål</li>
            <li>E-Commerce & Digital Strategi</li>
            <li>Analys & Rapportering</li>
            <li>Försäljningsbudget & Prognos</li>
            <li>Inköpsbudget</li>
            <li>Sortimentsstrategi & Sortimentsplanering</li>
            <li>Kampanjplan</li>
            <li>Inköpsplanering & Förpackningsoptimering</li>
            <li>Allokering & Lageroptimering</li>
            <li>Merchandising butik & online</li>
            <li>Digital transformation</li>
            <li>Affärsutveckling & Verksamhetsutveckling</li>
            <li>
              Konsumentdriven hållbarhetsutveckling inom E-handel och Logistik
            </li>
            <li>Förändringsledarskap & Projektledning</li>
            <li>
              Excel, Google Analytics, QlickView, Power BI, DaVinci, JDA, IFS,
              SAP, Magento
            </li>
          </ul>
        </Text>
      </Grid>
    </section>
  );
};

export default Toolbox;
